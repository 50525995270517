import '../App.css';
import React, { Fragment } from 'react';
import headshot from '../headshot.jpg';
import resume from '../resume.pdf';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      justCopied: false
    };
    this.handleCopyClick = this.handleCopyClick.bind(this);
  }

  handleCopyClick() {
    navigator.clipboard.writeText("hey@samsummer.design");
    this.setState({
      justCopied: true
    });
    setTimeout(() => {
      this.setState({
        justCopied: false
      });
    }, 1200);
  }

  render() {
    return (
      <Fragment>

        <div className="section-wrapper">
          <div>
            <h1 className="callout">Here to build beautiful products that solve worthwhile problems; lover of thoughtful typography, good writing, and magical finishing touches</h1>
            <a className="h2-link" href={resume} target="_blank">View résumé →</a><br />
          </div>
        </div>
        <div className="horizontally-padded-wrapper bottom-padded-wrapper">
            <hr/>
            <p className="light-gray-text">About</p>
        </div>
        <div className="horizontally-padded-wrapper flex-wrapper bottom-padded-wrapper">
          <div className="left-column width-30">
            <img
              alt="Headshot"
              className="headshot"
              src={headshot}
            />
          </div>
          <div className="right-column width-70">
            <div className="top-padded-wrapper">
              <p>I’m a multidisciplinary digital designer based in Seattle. My work integrates research, design, and engineering to create products that solve meaningful problems.</p>
              <p>After studying architecture and design at the University of Pennsylvania, I joined Wisr — an emergent ed tech startup — as the first design hire. Wisr grew from five to forty clients in two years and was acquired by EAB in 2021.</p>
              <p>I went on to join the University of Washington's research enterprise, designing software products that help researchers win and manage $1.6B in annual funding.</p>
              <p>Most recently, I founded <a href="https://foundation.studio" target="_blank">Foundation→</a>, a boutique design studio that works with outstanding designers, artists, and engineers to help businesses build better products. Foundation was named a 2022 and 2023 Top 5 Seattle Web Development Company by DesignRush, and we currently partner with organizations like Techstars and Open Core Ventures.</p>
            </div>
            <div className="light-gray-text availability-wrapper">
              Booked through August 2024
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default About;
