import './App.css';
import { Fragment } from 'react';

function CaseStudyHeader(props) {
    return (
        <Fragment>
            {props.announcement ?
                <div className="announcement">{props.announcement}</div>
                : null
            }
            <div className="vertically-padded-wrapper">
                <div className="flex-wrapper horizontally-padded-wrapper">
                    <div className="text-wrapper">
                        <h2>{props.summary}</h2>
                        {props.href ? <a href={props.href} target="_blank">Visit website →</a> : null}
                    </div>
                </div>
                <div className="flex-wrapper horizontally-padded-wrapper">
                    <div className="left-column width-35">
                        <dl>
                            <dt className="light-gray-text">When I worked with them</dt>
                            <dd>{props.timeframe}</dd>
                            <dt className="light-gray-text">Featured project length</dt>
                            <dd>{props.projectLength}</dd>
                            <dt className="light-gray-text">Project type</dt>
                            <dd>{props.projectType}</dd>
                        </dl>

                    </div>
                    <div className="right-column width-65">
                        <dl>
                            <dt className="light-gray-text">Hats worn</dt>
                            <dd>{props.myRole}</dd>
                            <dt className="light-gray-text">Tools used</dt>
                            <dd>{props.toolsUsed}</dd>
                            <dt className="light-gray-text">Teammates</dt>
                            <dd>{props.teammates}</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </Fragment >
    );
}

export default CaseStudyHeader;
