import './App.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
import checkmarkIcon from './checkmark-icon.svg';
import copyIcon from './copy-icon.svg';

class CustomNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            justCopied: false
        };
        this.handleCopyClick = this.handleCopyClick.bind(this);
    }

    // Copy email address to clipboard
    handleCopyClick() {
        navigator.clipboard.writeText("hey@samsummer.design");
        this.setState({
            justCopied: true
        });
        setTimeout(() => {
            this.setState({
                justCopied: false
            });
        }, 1200);
    }

    render() {
        return (
            <nav>
                <ul className="nav-links">
                    <NavLink activeClassName="nav-link-active" exact  to='/'>
                        <li className="hide-on-mobile nav-link">Select work</li>
                        <li className="hide-on-non-mobile nav-link">Work</li>
                    </NavLink>
                    <NavLink activeClassName="nav-link-active" to='/archive'>
                        <li className="hide-on-mobile nav-link">Project archive</li>
                        <li className="hide-on-non-mobile nav-link">Archive</li>
                    </NavLink>
                    <NavLink activeClassName="nav-link-active" to='/about'>
                        <li className="nav-link">About</li>
                    </NavLink>
                    <a
                        className="link-button hide-on-non-mobile"
                        href="mailto:hey@samsummer.design"
                    >
                        Email→
                    </a>
                    <button
                        className="link-button hide-on-mobile has-tooltip"
                        onClick={this.handleCopyClick}
                    >
                        hey@samsummer.design
                        <img className="copy-icon" src={this.state.justCopied == true ? checkmarkIcon : copyIcon} />

                        <span className="tooltip light-gray-text">
                            {this.state.justCopied == true
                                ? "Copied!"
                                : "Copy email address"
                            }
                        </span>

                    </button>
                </ul>
            </nav>
        );
    }
}

export default CustomNav;
