import '../App.css';
import { Fragment } from 'react';
import toeHero from '../hero-toe.png';
import toeImage1 from '../toe-image-1.png';
import toeImage2 from '../toe-image-2.png';
import toeImage4 from '../toe-image-4.png';
import toeImage7 from '../toe-image-7.png';
import toeImage8 from '../toe-image-8.png';
import toeImage13 from '../toe-image-13.png';
import toeImage14 from '../toe-image-14.png';
import CaseStudyHeader from '../CaseStudyHeader';
import CaseStudyFooter from '../CaseStudyFooter';

function TheOpportunityExchange() {
    return (
        <Fragment>
            <CaseStudyHeader
                href="https://www.theopportunityexchange.com/"
                myRole="UX design, UI design"
                projectLength="Three months"
                projectType="Freelance"
                summary="The Opportunity Exchange connects investors with development projects in underserved neighborhoods. The goal: fund development that actually helps people. I worked with the Opportunity Exchange and their partners to make it easier to collect information about communities from residents and leverage that information to find well-matched investors."
                teammates="One engineer, one PM, and a partner nonprofit"
                timeframe="March 2020 – December 2020"
                toolsUsed="Figma, Balsamiq, Illustrator"
            />
            <div className="hero-wrapper orange">
                <img
                    alt=""
                    className="hero"
                    src={toeHero}
                />
            </div>
            {/* <span className="angle-divider"></span> */}
            {/* <span className="section-label">Some label</span> */}
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Realigning styles</h2>
                </div>
                <div className="right-column width-70">
                    <p>When the Opportunity Exchange brought me on for a project, I started with some design housekeeping. Colors and typography had gotten jumbled in the year since the Opportunity Exchange had worked with an identity designer, so I realigned them with a basic pattern library that standardized styles, the core UI components I knew I’d need, and—to my delight—a set of custom icons.</p>
                    <figure>
                        <img
                            alt="Pattern library containing color swatches, icons, shadow styles, a type ramp, and some basic UI components"
                            src={toeImage2}
                        />
                        <figcaption>Pattern library to keep styles and components consistent</figcaption>
                    </figure>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Survey builder</h2>
                </div>
                <div className="right-column width-70">
                    <p>My main focus was a tool that would let partner organizations build surveys to collect information about a community from residents. The survey builder would need to handle a lot of conditional logic. If, for example,a respondent says agriculture is the biggest industry in a community, the followup questions shouldn’t focus on manufacturing. The complexity of this logic drove building the tool in-house instead of using an out-of-the box solution.</p>
                    <p>Most similar tools relegate conditional logic (e.g. <i>if this question is X, jump to Y</i>) to a modal or standalone page. With this approach, the survey creator loses the context offered by seeing all questions while setting up logic. It’s hard to visualize skip logic when the relevant questions are hidden.</p>
                    <figure>
                        <img
                            alt="Wireframe of the survey builder, with a header, left navigation menu, and two sample questions"
                            src={toeImage1}
                        />
                        <figcaption>Each question has a preview state, which shows how it will look in the live survey, and an editable state, which allows quick updates.</figcaption>
                    </figure>
                    <p>I used a right side panel to keep this context visible, and—moving to higher fidelity—I added arrows (visible only when a question’s side panel is open) showing all the questions where a respondent might be redirected after answering. You can check out the full prototype of the survey builder <a href="https://www.figma.com/proto/pyhufUXMUe0hMQCVSyHcP0/The-Opportunity-Exchange?node-id=47%3A10&scaling=scale-down-width" target="_blank">here</a>.</p>
                    <figure>
                        <img
                            alt="Mockup showing the survey builder's conditional logic side panel"
                            src={toeImage4}
                        />
                        <figcaption>The right side panel exposes conditional logic while keeping the questions visible. Meanwhile, arrows call out questions to which a respondent might be redirected after answering.</figcaption>
                    </figure>
                    <p>The frequent use of skip logic led me to show only one question at a time, since the response to a question might change all subsequent questions. This decision made a tradeoff: on one hand, showing one question at a time made surveys feel less intimidating. On the other, answering a question became a heavier interaction. Respondents would have to answer and indicate readiness to move to the next question. I mitigated this downside by <a href="https://www.figma.com/proto/pyhufUXMUe0hMQCVSyHcP0/The-Opportunity-Exchange?node-id=57%3A292&scaling=scale-down-width" target="_blank">automatically advancing after responding to single-select questions</a>.</p>
                    <figure>
                        <img
                            alt="Mockup of a survey question on a mobile device; only one question is displayed on the screen"
                            className="mobile-image"
                            src={toeImage8}
                        />
                        <figcaption className="mobile-figcaption">All surveys are mobile-responsive for respondents' convenience.</figcaption>
                    </figure>
                    <p>As responses roll in, administrators can see summary statistics, visualizations, <i>and</i> individual responses.</p>
                    <figure>
                        <img
                            alt="Mockup of an administrator's view of survey results, showing a pie chart for a single-select question"
                            src={toeImage7}
                        />
                        <figcaption>Different question types come with different visualization options. Single-select questions, for example, can be displayed as pie charts.</figcaption>
                    </figure>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Showing off communities</h2>
                </div>
                <div className="right-column width-70">
                    <p>The Opportunity Exchange team uses survey data to build profiles highlighting the unique features of each community. After selecting the tracts of land to include, they customize the content and layout, automatically importing statistics and visualizations from the associated survey.</p>
                    <figure>
                        <img
                            alt="Mockup of a community profile that highlights information like current projects, key industries, and landmarks"
                            src={toeImage13}
                        />
                        <figcaption>Community profile built with survey data</figcaption>
                    </figure>
                    <p>Investors can then browse communities via search, filters, and an interactive map. You can check out a community profile prototype <a href="https://www.figma.com/proto/pyhufUXMUe0hMQCVSyHcP0/The-Opportunity-Exchange?node-id=17%3A0&scaling=scale-down-width" target="_blank">here</a>.</p>
                    <figure>
                        <img
                            alt="Mockup showing filters, a list of communities, and a map of communities"
                            src={toeImage14}
                        />
                        <figcaption>Investors can jump to community profiles from the list or by clicking directly on the map.</figcaption>
                    </figure>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Handoff, results, and takeaways</h2>
                </div>
                <div className="right-column width-70">
                    <p>This project reminded me how much I love working with startups. Everyone involved felt invested in the results, and that made it easy for me to get excited.</p>
                    <p>The Opportunity Exchange has continued to grow and now has active projects in 27 states. As business took off, I didn’t have the bandwidth to handle all their design work, but—when they brought on a full-time designer—they gave me the awesome opportunity to help train her. I prepared and delivered eight hour-long workshops walking through the basics of visual design, research, accessibility, prototyping, and presentation. It was an awesome way to bookend the project, and I’m confident that the Opportunity Exchange’s product is in good hands.</p>
                </div>
            </div>
            <CaseStudyFooter
                otherCaseStudiesCovers={[
                    1,
                    2
                ]}
                otherCaseStudies={[
                    "University of Washington Office of Research Information Services",
                    "Wisr"
                ]}
                otherCaseStudiesSlugs={[
                    "/uw-oris",
                    "/wisr"
                ]}
            />
        </Fragment>
    );
}

export default TheOpportunityExchange;
