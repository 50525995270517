import '../App.css';
import { Fragment } from 'react';
import airtableHero from '../hero-airtable.png';
import wisrImage1 from '../wisr-image-1.png';
import CaseStudyHeader from '../CaseStudyHeader';
import CaseStudyFooter from '../CaseStudyFooter';

function Airtable() {
  return (
    <Fragment>
      <CaseStudyHeader
        href="https://airtable.com/"
        myRole="Usability research, design strategy"
        projectLength="Three months"
        projectType="Sponsored research"
        summary="Airtable helps with anything related to storing information: from keeping track of contacts to coordinating marketing campaigns to building analytics dashboards. I worked with a team of master’s students and Airtable’s head of research to study users’ first-time experiences in the product, identify areas of confusion, and recommend refinements."
        teammates="Three other design and engineering master's students"
        timeframe="January 2021 – March 2021"
        toolsUsed="Google Forms, Excel, iMovie"
      />
      <div className="hero-wrapper cyan">
        <img
          alt=""
          className="hero"
          src={airtableHero}
        />
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Challenge</h2>
        </div>
        <div className="right-column width-70">
            <p>Airtable suits a huge range of use cases, so onboarding first-time users is difficult. The experience should highlight the features relevant to a user without presenting irrelevant or overwhelming information. This study was particularly concerned with how marketers learn the product. Marketers are a fast-growing user segment with needs that diverge from those of, say, project managers who use Airtable.</p>
            <p>My team developed and executed a three-month research study to:
                <ol>
                    <li>Identify onboarding pain points</li>
                    <li>Assess first-time users’ understanding of Airtable terminology</li>
                    <li>Recommend design refinements</li>
                </ol>
            </p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Process</h2>
        </div>
        <div className="right-column width-70">
            <p>My team conducted a task-based usability study with eight participants. Using Zoom to accommodate COVID-19 restrictions, participants used their own laptops. Each session lasted an hour, with time allotted for introductions, task run-through, a terminology quiz, and debriefing.</p>
            <p>Participants each completed 14 tasks while explaining their thought processes. Participants rated tasks for difficulty, on a scale of 1 to 5, where 1 represented very difficult and 5 represented very easy. Each session was moderated by one moderator while at least one notetaker documented insights via a note-taking template. When a participant struggled with a task, the moderator would explore the experience with open-ended questions and provide guidance when needed.</p>
            <p>After completing the tasks, participants completed a quiz designed to measure comprehension of terminology used in the product (table, record, base, etc.). Participants were each compensated with a $100 Amazon gift card.</p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Findings &amp; recommendations</h2>
        </div>
        <div className="right-column width-70">
            <p>Issues were prioritized based on <strong>frequency</strong> (occurences out of eight participants) and <strong>severity</strong> (one of three categories):
                <ul>
                    <li><strong>High-impact:</strong> prevented a user from completing a key task</li>
                    <li><strong>Medium-impact:</strong> caused significant delays or confusion</li>
                    <li><strong>Low-impact:</strong> created a minor inconvenience</li>
                </ul>
            </p>
            <p>Three key areas for improvement emerged.</p>
            <p><strong>Information overload:</strong> No particpants read all of the onboarding instructions, with one explaining:</p>
            <blockquote>“There are a lot of instructions&hellip; it was helpful to get started, but now it’s overwhelming.”</blockquote>
            <p>Participants went on to explain that the interface was too text heavy. Notably, all eight particpants watched a one-minute video clip presenting most of the same information. Two of the particpants expressed that they would rather contact a support specialist than learn the product unassisted.</p>
            <p>Based on these findings, my team recommended relying more heavily on video content, reduce the amount of dummy data in sample templates, and make the live assistance "help" button more prominent during onboarding.</p>
            <p><strong>Linked records:</strong> Linked records work like Excel's VLOOKUP function, allowing data in one cell to be synced to a different cell. Five of eight participants took longer than 45 seconds to discover the linked records feature, and three of those five had to be assisted to complete the task. Even after discovering the feature, four of eight expressed confusion about the settings used to configure linked records.</p>
            <p>Based on these findings, my team recommended adding an alternative path for linking two records, adding help text to explain complex settings, and applying unique styling to lookup fields to communicate that they can't be edited directly.</p>
            <p><strong>Terminology comprehension:</strong> On average, participants were able to select the correct definition for only 40% of key Airtable terms. Confusion around terminology didn't necessarily prevent participants from completing tasks in the study, but we predicted that it would make it more difficult for users to grasp certain advanced features.</p>
            <p>Based on these findings, my team recommended including key terms in onboarding tutorials and using those terms in interface labels to help users mentally map them to the correct components.</p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Results</h2>
        </div>
        <div className="right-column width-70">
            <p>My team handed off our research via a written report and a short highlight video featuring key moments in the sessions that informed recommendations. We used comments on the written report, a Google Doc, to collaborate with Airtable's in-house researchers, designers, and engineers. Many of minor receommendations were immediately written into development tickets, while some of the more significant ones were added to the product roadmap. Andy Warr, Airtable's head of research and our sponsor for the project, said:</p>
            <blockquote>"Your report is well-designed and thoughtfully structured - I really liked that you presented findings, supporting data, and recommendations&hellip; I especially love the highlight video - 8 hours of testing condensed into less than 30 minutes&hellip;"</blockquote>
        </div>
      </div>
      <CaseStudyFooter
        otherCaseStudiesCovers={[
          1,
          0
        ]}
        otherCaseStudies={[
          "University of Washington Office of Research Information Services",
          "The Opportunity Exchange"
        ]}
        otherCaseStudiesSlugs={[
          "/uw-oris",
          "/the-opportunity-exchange"
        ]}
      />
    </Fragment>
  );
}

export default Airtable;
