import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './pages/About';
import Airtable from './pages/Airtable';
import CustomNav from './Nav';
import ScrollToTop from './ScrollToTop';
import Work from './pages/Work';
import Archive from './pages/Archive';
import P5Experiments from './pages/P5Experiments';
import TheOpportunityExchange from './pages/TheOpportunityExchange';
import TrailSafe from './pages/TrailSafe';
import UwOris from './pages/UwOris';
import Wisr from './pages/Wisr';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="page-wrapper">
          <CustomNav />
          <Switch>
            <Route component={Work} path="/" exact />
            <Route component={About} path="/about" />
            <Route component={Airtable} path="/airtable" />
            <Route component={Archive} path="/archive" />
            <Route component={P5Experiments} path="/p5-experiments" />
            <Route component={TrailSafe} path="/trailsafe" />
            <Route component={TheOpportunityExchange} path="/the-opportunity-exchange" />
            <Route component={UwOris} path="/uw-oris" />
            <Route component={Wisr} path="/wisr" />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
