import '../App.css';
import { Fragment } from 'react';
import wisrHero from '../hero-wisr.png';
import wisrImage1 from '../wisr-image-1.png';
import wisrImage2 from '../wisr-image-2.png';
import wisrImage3 from '../wisr-image-3.png';
import wisrImage4 from '../wisr-image-4.png';
import wisrImage5 from '../wisr-image-5.png';
import wisrImage6 from '../wisr-image-6.png';
import wisrImage9 from '../wisr-image-9.png';
import wisrImage10 from '../wisr-image-10.jpg';
import wisrMovie1 from '../wisr-movie-1.mov';
import CaseStudyHeader from '../CaseStudyHeader';
import CaseStudyFooter from '../CaseStudyFooter';

function Wisr() {
  return (
    <Fragment>
      <CaseStudyHeader
        announcement="Wisr was acquired by EAB in 2021 after growing to over sixty client universities."
        href="https://getwisr.com/"
        myRole="UX design, UI design, front-end engineering, marketing"
        projectLength="Two months"
        projectType="In-house"
        summary="I joined Wisr as the first design hire and spent two years helping students navigate the bookends of college: adjusting to life on campus and finding a job. During my tenure, we grew from five to forty partner universities and doubled our team’s size. I designed—and often helped engineer—core features, including discussion forums that are now used by tens of thousands of students and alumni."
        teammates="Three engineers"
        timeframe="August 2017 – July 2019"
        toolsUsed="Sketch, Balsamiq, Illustrator, PhotoShop, React, HTML, CSS"
      />
      <div className="hero-wrapper yellow">
        <img
          alt=""
          className="hero"
          src={wisrHero}
        />
      </div>
      {/* <span className="angle-divider"></span> */}
      {/* <span className="section-label">Some label</span> */}
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>All the hats</h2>
        </div>
        <div className="right-column width-70">
          <p>In two years at Wisr, I focused on UX and UI design but often found myself dabbling in marketing and front-end engineering. Some days I built landing pages (we hosted 33 events in 24 cities in 2019). I spent a few weeks redesigning our marketing site. I even got to make a big sign for our office. During one project, front-end development became our limiting factor, and I got to spend eight months working on the engineering team, learning React and regularly contributing code.</p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Discussion forum</h2>
        </div>
        <div className="right-column width-70">
          <p>The MVP Wisr launched had tools to help members connect one-on-one: direct messages and a call scheduler. The discussion forum feature we decided to build needed to support a wider range of use cases, from nuanced class debates to simple announcements.</p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Forum research</h2>
        </div>
        <div className="right-column width-70">
          <p>Kicking off this project, we didn’t have a comprehensive list of use cases. So, while planning this feature, I conducted ten interviews with users, often sneaking them in during breaks at on-campus events.</p>
          <p>I also reviewed products with similar features including Medium, Reddit, Switchboard, and all the popular social media sites. I noticed two main schemas: flat and nested.</p>
          <p>In the flat schema, all posts live at the same level of hierarchy. There’s no direct relationship between one post and another. Flat discussion forums offer a smooth reading experience and discourage the fracturing of a conversation into sub-threads.</p>
          <figure>
            <img
              alt="All posts in a flat discussion live at the same level of hierarchy"
              src={wisrImage1}
            />
            <figcaption>Flat schema: posts display chronologically at the same level of hierarchy</figcaption>
          </figure>
          <figure>
            <img
              alt="Medium uses a flat comment section"
              src={wisrImage3}
            />
            <figcaption>Comments on Medium use a simple, flat schema.</figcaption>
          </figure>
          <p>In the nested schema, on the other hand, some posts are <i>replies</i> to other posts. In some cases, those replies can have replies (and so on ad infinitum). While these forums display posts out of order, they facilitate deeper conversations by better accommodating sub-threads.</p>
          <figure>
            <img
              alt="Some posts in a nested discussion are nested under their parent posts"
              src={wisrImage2}
            />
            <figcaption>Nested schema: each reply displays indented directly below its parent</figcaption>
          </figure>
          <figure>
            <img
              alt="Switchboard uses a nested structure, with replies indented under their parent posts"
              src={wisrImage5}
            />
            <figcaption>Switchboard uses a nested schema but only displays one layer of hierarchy. In other words, a reply to a reply is indented exactly as far as a regular reply.</figcaption>
          </figure>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Not quite flat, but definitely not nested</h2>
        </div>
        <div className="right-column width-70">
          <p>“Don’t make Reddit.” That’s what we kept saying. At the time, Reddit looked like this:</p>
          <figure>
            <img
              alt="A complex discussion shows many layers of nested replies"
              src={wisrImage4}
            />
            <figcaption>Products like Reddit can feel overwhelming when there are too many layers of replies.</figcaption>
          </figure>
          <p>Clearly, <i>too much</i> nesting gets confusing and breaks a conversation into scattered fragments. I liked the egalitarianism of flat forums. Every post receives equal consideration, free from the deemphasis that comes with indenting replies. Still, posts should be able to build on their predecessors—and not necessarily on just their <i>immediate</i> predecessors.</p>
          <p>The solution I came up with supports replies, but takes a unique approach to representing parent-child relationships. Each reply displays in two places:</p>
          <ul>
            <li>Where it belongs chronologically (its “chronological home”)</li>
            <li>Nested under its parent, collapsed by default</li>
          </ul>
          <figure>
            <video alt="Replies appear in both their chronological homes and nested under their parent posts" controls src={wisrMovie1}></video>
            <figcaption>Replies appear in both their chronolgoical homes and nested under their parent posts, <i>unless</i> the reply is immediately after the parent. In that case, the nested copy isn't displayed.</figcaption>
          </figure>
          <p>This allows readers to follow the sequential flow flow of the discussion while leaving the option to dig into discourse under a particularly interesting post. To provide context when a reply is displayed in its chronological home, every reply quotes its parent. Each collapsed reply gives the option to “go to reply” and each reply displayed in its chronological home gives the option to “go to quoted post.” Using these breadcrumbs, readers can traverse up or down any sub-thread.</p>
          <figure>
            <img
              alt="Readers can jump from a parent post to any of its replies or from a reply to its parent post"
              src={wisrImage6}
            />
            <figcaption>Readers can jump between a parent and a reply, making it easy to follow sub-threads.</figcaption>
          </figure>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Results</h2>
        </div>
        <div className="right-column width-70">
          <p>Wisr discussion forums took off immediately. Now, more than sixty universities have active forums. Every incoming student at the University of Chicago meets their peer advisor and co-advisees via a Wisr discussion forum. First-generation students and alumni at Oberlin College use a forum to share resources and job opportunities. And a forum helps Wellesley College’s admitted students ask questions about orientation and get to know each other before arriving on campus.</p>
          <figure>
            <img
              alt="A rising senior at UChicago greets incoming mentees in a Wisr discussion forum"
              src={wisrImage9}
            />
            <figcaption>A peer mentor greets incoming students at the University of Chicago via a Wisr discussion forum.</figcaption>
          </figure>
        </div>
      </div>
      <CaseStudyFooter
        otherCaseStudiesCovers={[
          1,
          0
        ]}
        otherCaseStudies={[
          "University of Washington Office of Research Information Services",
          "The Opportunity Exchange"
        ]}
        otherCaseStudiesSlugs={[
          "/uw-oris",
          "/the-opportunity-exchange"
        ]}
      />
    </Fragment>
  );
}

export default Wisr;
