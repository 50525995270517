import '../App.css';
import { Fragment } from 'react';
import trailSafeImage1 from '../trailsafe-image-1.png';
import trailSafeImage2 from '../trailsafe-image-2.png';
import trailSafeImage3 from '../trailsafe-image-3.png';
import trailSafeImage4 from '../trailsafe-image-4.png';
import trailSafeImage5 from '../trailsafe-image-5.png';
import trailSafeImage6 from '../trailsafe-image-6.png';
import trailSafeImage7 from '../trailsafe-image-7.jpeg';
import CaseStudyHeader from '../CaseStudyHeader';

function TrailSafe() {
    return (
        <Fragment>
            <CaseStudyHeader
                myRole="Research, UX design, project management, prototyping"
                projectLength="Three months"
                projectType="Academic project"
                summary="I interviewed five search-and-rescue professionals and four hikers, across skill levels, who survived dangerous situations outdoors. Informed by my findings, I worked with a team of master's students to design, prototype, and test TrailSafe, a web app that gives hikers live condition information at the trailheads of dangerous hikes."
                teammates="Three other design and engineering master's students"
                timeframe="October 2020 – December 2020"
                toolsUsed="Figma, WordPress, JavaScript, HTML, CSS, Google Analytics"
            />
            <span className="angle-divider"></span>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>My search-and-rescue experience</h2>
                </div>
                <div className="right-column width-70">
                    <p>I spent two summers working at high altitude in the White Mountains of New Hampshire, which famously boast the "worst weather in the world." During those stints, I participated in two search-and-rescue operations and got a firsthand glimpse of how easy it is for hikers to underestimate the difficulty of hikes.</p>
                    <figure>
                        <img
                            alt="Three people (including Sam) sitting lounging on rocks overlooking a sprawling mountain panorama"
                            src={trailSafeImage7}
                        />
                        <figcaption>The area where I was working didn't offer a lot of protection from the elements, and emergency services would take several hours to respond to a crisis that far into the backcountry.</figcaption>
                    </figure>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Research and findings</h2>
                </div>
                <div className="right-column width-70">
                    <p>My team investigated the biggest threats facing hikers and the steps they're currently taking to mitigate them. We surveyed 57 regular hikers, analyzed 10 existing wilderness safety products, and interviewed 9 subjects. I conducted the interviews over Zoom, spending about half an hour each with five search-and-rescue experts and four hikers who survived dangerous situations. Combining the research findings, we developed two personas that embody the highest-risk hikers.</p>
                    <figure>
                        <img
                            alt="Tenderfoot Tom is young and healthy, but he overestimates his ability and tends to take on dangerous hikes. Old-Timer Oscar has a lot of wilderness experience, but isn't as fit as he used to be."
                            src={trailSafeImage2}
                        />
                        <figcaption>Tenderfoot Tom is young and healthy, but he overestimates his ability and tends to take on dangerous hikes. Old-Timer Oscar has a lot of wilderness experience, but isn't as fit as he used to be.</figcaption>
                    </figure>
                    <p>Other key insights included:</p>
                    <ul>
                        <li>Distance can be misleading, leading many hikers to underestimate difficulty.</li>
                        <li>Terrain, weather, and elevation change heavily impact difficulty.</li>
                        <li>Low- to high-elevation hikes make it easy to misjudge weather at the summit.</li>
                        <li>Few hikers take recommended precautions like packing the "ten essentials."</li>
                        <li>Many hikers depend on cell service for navigating and handling emergencies.</li>
                    </ul>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Concept</h2>
                </div>
                <div className="right-column width-70">
                    <p>Not all hikers do research before embarking. By placing information at trailheads, we could make sure <i>everyone</i> hiking a trail would see it. The concept was simple: put a QR code on the kiosks at the trailheads of dangerous hikes. Hikers would scan the codes and see key day-of conditions for the hike they would attempt.</p>
                    <figure>
                        <img
                            alt="Hand sketch showing the TrailSafe concept: hikers scan a QR code at a trailhead and navigate to a page with details about trail conditions"
                            src={trailSafeImage4}
                        />
                        <figcaption>Hikers scan a QR code at the trailhead to see details about trail condtions, with special emphasis given to emergency warnings and summit weather.</figcaption>
                    </figure>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Information to include</h2>
                </div>
                <div className="right-column width-70">
                    <p>The biggest design challenge we faced was balancing showing all critical hike details with avoiding information overload. Everything on the page should drive hikers' decisions on whether to attempt a hike.</p>
                    <div className="flex-wrapper">
                        <div className="left-column">
                            <figure>
                                <img
                                    alt="Wireframe showing the key sections on a trail details page: hike overview, current summit weather, elevation profile, and recommended gear"
                                    className="mobile-image"
                                    src={trailSafeImage3}
                                />
                                <figcaption className="mobile-figcaption">Early wireframe showing the key sections on a trail details page: emergency warning (if applicable), hike overview, current summit weather, elevation profile, and recommended gear</figcaption>
                            </figure>
                        </div>
                        <div className="right-column">
                            <p><strong>Emergency warning.</strong> This banner is displayed when conditions are particularly risky: when weather warnings are in effect, the trail is snowy, or the trail is closed.</p>
                            <p><strong>Hike overview.</strong> This section summarizes the hike and aims to assign a general difficulty. We included elevation gain and average hike time to contextualize the distance (three miles around Seattle isn't the same as three miles up and down a mountain).</p>
                            <p><strong>Current summit weather.</strong> For low- to high-elevation hikes, it might be 60˚ and sunny at the trailhead and 30˚ and sleeting at the summit. Unpreparedness for weather was the number one risk factor search-and-rescue experts mentioned during interviews.</p>
                            <p><strong>Elevation profile.</strong> Novice hikers might not be able to interpret 3,000 feet of elevation gain. This section visualizes the steepness of the hike's incline and explains the elevation change in terms everyone can understand: number of flights of stairs.</p>
                            <p><strong>Recommended gear.</strong> The goal for this section was to discourage hikers who didn't have the right equipment for a hike. But, through research and testing, we determined that hikers who've already driven to a trailhead are unlikely to turn back because they forgot a headlamp. We ultimately removed this section and refocused on communicating conditions and trail information.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Testing in the wild</h2>
                </div>
                <div className="right-column width-70">
                    <p>Once we had the content nailed down, I built a simple proof of concept with WordPress. It included a "trails in the area" page and a trail details page for the hike up Mount Si, a good candidate for testing because if its tendency toward bad weather near the summit. One Saturday, I hardcoded weather conditions for the day and actually posted a sign that linked to the proof of concept site at the Mount Si trailhead.</p>
                    <div className="flex-wrapper">
                        <div className="left-column">
                            <figure>
                                <img
                                    alt="TrailSafe sign with a QR code on the trailhead kiosk at the base of Mount Si"
                                    src={trailSafeImage5}
                                />
                                <figcaption>I posted a guerrilla user testing sign at the Mount Si trailhead.</figcaption>
                            </figure>
                        </div>
                        <div className="right-column">
                            <figure>
                                <img
                                    alt="Scanning the QR code opens the hike details page with information about the climb up Mount Si"
                                    src={trailSafeImage6}
                                />
                                <figcaption>Scanning the QR code opens a hard-coded hike details page.</figcaption>
                            </figure>
                        </div>
                    </div>
                    <p>Throughout the day, 11 hikers scanned the QR code. Via Google Analytics, I tracked which sections of the details page each hiker viewed, helping my team understand what information hikers were most interested in.</p>
                </div>
            </div>
            <div className="section-wrapper">
                <div className="left-column width-30">
                    <h2>Limitations, results, and takeaways</h2>
                </div>
                <div className="right-column width-70">
                    <p>Our solution is limited by two main variables: access to the internet at a trailhead and quality of summit weather data. Some trailheads don't offer any coverage, so hikers would be unable to load any TrailSafe pages. That said, the hikes with the most emergencies actually tend to have more frontcountry trailheads with at least some service. High-elevation weather data is only available for a handful of popular mountains, but—again—these tourist destinations are the best candidates for TrailSafe.</p>
                    <p>Research paid off in this project. Initially, we toyed with the idea of building an app to make it easier for hikers to share their itineraries, but our interviewees pointed out that hikers are already neglecting emergency precautions. It would be easier, we concluded, to help hikers <i>avoid</i>, rather than <i>deal with</i>, emergencies</p>
                    <figure>
                        <img
                            alt="Mockup of the hike details page for Mount Si on an iPhone"
                            src={trailSafeImage1}
                        />
                        <figcaption>The final hike details page</figcaption>
                    </figure>
                    <p>The final prototype captures the most important information about a trail without overwhelming beginners with complex stats and recommendations. My teammate, Danny, cut together a nice video walking through the project:</p>
                    <iframe className="youtube" src="https://www.youtube.com/embed/0AhZsG-1riY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </Fragment>
    );
}

export default TrailSafe;
