import '../App.css';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import sectionCover1 from '../section-cover-1.png';
import sectionCover2 from '../section-cover-2.png';
import sectionCover3 from '../section-cover-3.png';
import sectionCover5 from '../section-cover-5.png';

class Work extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            justCopied: false
        };
        this.handleCopyClick = this.handleCopyClick.bind(this);
    }

    // Copy email address to clipboard
    handleCopyClick() {
        navigator.clipboard.writeText("hey@samsummer.design");
        this.setState({
            justCopied: true
        });
        setTimeout(() => {
            this.setState({
                justCopied: false
            });
        }, 1200);
    }

    render() {
        return (
            <Fragment>
                <div className="section-wrapper">
                    <div>
                        <h1 className="callout">
                            Sam Summer is an award-winning product designer who builds websites, apps, and visual identities.
                        </h1>
                        <Link className="h2-link" to='/about'>More about me →</Link>
                    </div>
                </div>
                <div className="horizontally-padded-wrapper bottom-padded-wrapper">
                    <hr/>
                    <p className="light-gray-text">Select work</p>
                </div>
                <Link
                    className="section-wrapper section-button reverse-on-small-screens"
                    to='/uw-oris'
                >
                    <div className="left-column">
                        <h2>University of Washington Office of Research Information Services</h2>
                        <p className="light-gray-text">In-house&nbsp;&nbsp;/&nbsp;&nbsp;October 2019 – August 2021</p>
                        <p>Applying for research funding is slow and hard. We make it fast and easy so researchers at the University of Washington can focus on their research.</p>
                        <Link
                            className="read-more-button"
                            to='/uw-oris'
                        >
                            Read more
                        </Link>
                    </div>
                    <div className="right-column">
                        <img
                            alt="Summary screen of a research budget"
                            src={sectionCover2}
                        />
                    </div>
                </Link>
                <Link
                    className="section-wrapper section-button reverse-on-small-screens"
                    to='/the-opportunity-exchange'
                >
                    <div className="left-column">
                        <h2>The Opportunity Exchange</h2>
                        <p className="light-gray-text">Freelance&nbsp;&nbsp;/&nbsp;&nbsp;March 2020 – December 2020</p>
                        <p>Do we need more luxury apartments? The Opportunity Exchange helps investors put money into projects that do more for the people living nearby.</p>
                        <Link
                            className="read-more-button"
                            to='/the-opportunity-exchange'
                        >
                            Read more
                        </Link>
                    </div>
                    <div className="right-column">
                        <img
                            alt="Map of communities with a set of filters"
                            src={sectionCover1}
                        />
                    </div>
                </Link>


                <Link
                    className="section-wrapper section-button reverse-on-small-screens"
                    to='/airtable'
                >
                    <div className="left-column">
                        <h2>Airtable</h2>
                        <p className="light-gray-text">Sponsored research&nbsp;&nbsp;/&nbsp;&nbsp;January 2021 – March 2021</p>
                        <p>Airtable offers the features of a database through a no-code interface. My team worked with Airtable's head of research to streamline the first-time user experience.</p>
                        <Link
                            className="read-more-button"
                            to='/airtable'
                        >
                            Read more
                        </Link>
                    </div>
                    <div className="right-column">
                        <img
                            alt="Screen showing templates that can be used to create databases"
                            src={sectionCover5}
                        />
                    </div>
                </Link>


                <Link
                    className="section-wrapper section-button reverse-on-small-screens"
                    to='/wisr'
                >
                    <div className="left-column">
                        <h2>Wisr</h2>
                        <p className="light-gray-text">In-house&nbsp;&nbsp;/&nbsp;&nbsp;August 2017 – July 2019</p>
                        <p>Getting good grades is the easy part. Wisr helps college students with everything else: adjusting to campus, finding jobs, and (occasionally) braving a pandemic.</p>
                        <Link
                            className="read-more-button"
                            to='/wisr'
                        >
                            Read more
                        </Link>
                    </div>
                    <div className="right-column">
                        <img
                            alt="Home page of Wisr's marketing website"
                            src={sectionCover3}
                        />
                    </div>
                </Link>
            </Fragment>
        );
    }
}

export default Work;
