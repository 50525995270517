import '../App.css';
import { Fragment } from 'react';
import orisHero from '../hero-oris.png';
import orisImage1 from '../oris-image-1.png';
import orisImage2 from '../oris-image-2.png';
import orisImage3 from '../oris-image-3.jpg';
import orisImage4 from '../oris-image-4.png';
import orisImage5 from '../oris-image-5.png';
import orisImage6 from '../oris-image-6.png';
import orisGif1 from '../oris-gif-1.gif';
import CaseStudyHeader from '../CaseStudyHeader';
import CaseStudyFooter from '../CaseStudyFooter';

function UwOris() {
  return (
    <Fragment>
      <CaseStudyHeader
        href="https://www.washington.edu/research/oris/"
        myRole="UX design, UI design, research, animation, business analysis"
        projectLength="Eight months"
        projectType="In-house"
        summary="Too many PhDs spend their days struggling through paperwork. ORIS’s goal is simple: accelerate research. Among the projects I’ve worked on since joining ORIS in October 2019 is the redesign of a budget builder that now helps researchers at the University of Washington win and manage $1.6B annually."
        teammates="Five developers, one test engineer, two business analysts, and two PMs"
        timeframe="October 2019 – present"
        toolsUsed="Figma, Illustrator, After Effects"
      />
      <div className="hero-wrapper purple">
        <img
          alt=""
          className="hero"
          src={orisHero}
        />
      </div>
      {/* <span className="angle-divider"></span> */}
      {/* <span className="section-label">Some label</span> */}
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Challenges</h2>
        </div>
        <div className="right-column width-70">
          <p>ORIS’s products are massive, with pockets of code that haven’t been touched in ten years. Even simple styling changes can break things in unexpected ways.</p>
          <p>The subject matter is equally daunting. I’ve never had to navigate such convoluted business requirements, and the virus-driven transition to working from home added another challenge to learning about a complex domain.</p>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Working faster</h2>
        </div>
        <div className="right-column width-70">
          <p>When I joined, ORIS’s design team worked mostly in Illustrator and was trial-running Figma. One of my biggest contributions to the team was keeping wireframes, prototypes, and artifacts of all sorts updated and easy to find. I live the philosophy that every conversation goes more smoothly with something to look at, so I made sure every development ticked linked to the related prototype and always used visuals—in Figma, on paper, or on a whiteboard—while discussing a feature.</p>
          <p>During my first few months at ORIS, I built a pattern library in Figma, creating a toolbox of components and styles that matched those our engineering team used (and making some improvements along the way). The result: I could prototype about three times faster, allowing me to prototype earlier in a project and more often.</p>
          <figure>
            <img
              alt="Pattern library with basic styles and UI components"
              src={orisImage2}
            />
            <figcaption>Color swatches page of the ORIS pattern library</figcaption>
          </figure>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>The $500MM budget builder</h2>
        </div>
        <div className="right-column width-70">
          <p>I worked with an awesome team to redesign a tool that helps researchers apply for and manage funding. The budget builder is like a fusion of Excel and TurboTax, automating calculations and acting as a friendly mediator between humans and nightmarish sponsor paperwork.</p>
          <p>We made too many improvements to enumerate here, but I’m proudest of making the tool more flexible. The original budget builder forced researchers to fill out a four-page wizard just to create a blank budget. Often, budget preparers wouldn’t even have the information they were being asked for at the time of budget creation.</p>
          <figure>
            <img
              alt="First page of the intimidatingly long budget creation wizard"
              src={orisImage4}
            />
            <figcaption>The first of four pages of the original budget builder's intimidatingly long wizard setup wizard</figcaption>
          </figure>
          <p>The redesigned tool creates a budget in a single click and prompts users to enter details when—and <i>only</i> when—they do something that requires that specific data.</p>
          <figure>
            <img
              alt="An empty state informs a budget preparer that it's time to set up budget periods"
              src={orisGif1}
            />
            <figcaption>This empty state guides budget preparers to set spending periods when they try to start adding costs.</figcaption>
          </figure>
          <p>The original budget builder emphasized budget preparation and ignored the equally important review process. I worked with an advisory group of about ten veteran budget reviewers to design a budget summary view that highlights information they need to confirm, accelerating the process and reducing errors. </p>
          <figure>
            <img
              alt="Summary view of a budget, outlining key costs and totals"
              src={orisImage5}
            />
            <figcaption>The summary view outlines key costs and totals to make budget review easier.</figcaption>
          </figure>
        </div>
      </div>
      <div className="section-wrapper">
        <div className="left-column width-30">
          <h2>Results</h2>
        </div>
        <div className="right-column width-70">
          <p>The University of Washington houses one of the largest research enterprises in the world, and I feel lucky to support such impactful work. Watching researchers who use ORIS products make breakthroughs in areas like COVID-19 diagnosis has been a surreal experience.</p>
          <figure>
            <img
              alt="News headline about a University of Washington affiliated researcher making a breakthrough with the COVID-19 antibody test"
              src={orisImage6}
            />
            <figcaption>I got serious warm-and-fuzzies seeing groundbreaking COVID-19 research coming from scientists who use products I designed.</figcaption>
          </figure>
          <p>Starting in mid-2021, every researcher at the University of Washington will use the budget builder for every project. It’s mind-blowing to think that we built a tool that works for such a wide range of people studying fields from epidemiology to architecture.</p>
          <p>Throughout the project, the design and engineering teams constantly discussed how budget builder patterns could improve other ORIS products. Every feature we’ve built since has leaned heavily on that foundational system, meaning faster development and more consistency.</p>
        </div>
      </div>
      <CaseStudyFooter
        otherCaseStudiesCovers={[
          0,
          2
        ]}
        otherCaseStudies={[
          "The Opportunity Exchange",
          "Wisr"
        ]}
        otherCaseStudiesSlugs={[
          "/the-opportunity-exchange",
          "/wisr"
        ]}
      />
    </Fragment>
  );
}

export default UwOris;
