import '../App.css';
import nqwImage1 from '../nqw-image-1.png';
import nqwImage2 from '../nqw-image-2.png';
import nqwImage3 from '../nqw-image-3.png';
import nqwImage4 from '../trailsafe-image-1.png';
import nqwImage5 from '../nqw-image-4.png';
import nqwImage7 from '../flightjive.png';
import sectionCover4 from '../section-cover-4.png';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Archive() {
    return (
        <Fragment>
            <div className="section-wrapper">
                <div>
                    <h1 className="callout">Personal projects, pro bono work, and experiments that don’t quite fit anywhere else</h1>
                    <Link className="h2-link" to='/'>Browse select work →</Link><br />
                </div>
            </div>

            <div className="horizontally-padded-wrapper bottom-padded-wrapper">
                <hr/>
                <p className="light-gray-text">Project archive</p>
            </div>

                            <a
                className="section-wrapper section-button reverse-on-small-screens"
                href="https://flightjive.com/" 
                target="_blank"
            >
                <div className="left-column">
                    <h2>FlightJive</h2>
                    <p className="light-gray-text">Personal project&nbsp;&nbsp;/&nbsp;&nbsp;July 2021 – August 2021</p>
                    <p>FlightJive is a simple proof-of-concept React app built on Kiwi's Tequila API. Say where two people are coming from, and FlightJive will find the destination city that minimizes the cost of air travel.</p>
                    <a
                        className="read-more-button"
                        href="https://flightjive.com/" 
                        target="_blank"
                    >
                        Find a place to meet
                    </a>
                </div>
                <div className="right-column">
                    <img
                        alt="A React app for finding the cheapest common desination for air travel"
                        src={nqwImage7}
                    />
                </div>
            </a>

                    <a
                className="section-wrapper section-button reverse-on-small-screens"
                href="https://topchessclock.com/" 
                target="_blank"
            >
                <div className="left-column">
                    <h2>A Better Chess Clock</h2>
                    <p className="light-gray-text">Personal project&nbsp;&nbsp;/&nbsp;&nbsp;June 2021</p>
                    <p>The most popular online chess clocks are remarkably bad. They're littered with ads, have tiny buttons, and break down on small screens. My version accommodates clumsy fingers and fast play on any device.</p>
                    <a
                        className="read-more-button"
                        href="https://topchessclock.com/" 
                        target="_blank"
                    >
                        Set the clock
                    </a>
                </div>
                <div className="right-column">
                    <img
                        alt="A sleek, modern chess clock with two timers and a switch button"
                        src={nqwImage5}
                    />
                </div>
            </a>

            <Link
                className="section-wrapper section-button hide-on-mobile reverse-on-small-screens"
                to='/p5-experiments'
            >
                <div className="left-column">
                    <h2>Experiments with p5.js</h2>
                    <p className="light-gray-text">Academic project&nbsp;&nbsp;/&nbsp;&nbsp;October 2020 – December 2020</p>
                    <p>Programming in p5.js, I explored a handful of experimental interactions ranging from a cursor-dimmed lightbulb to a JavaScript-only platform game.</p>
                    <Link
                        className="read-more-button"
                        to='/p5-experiments'
                    >
                        See the experiments
                    </Link>
                </div>
                <div className="right-column">
                    <img
                        alt="A dimmed lightbulb shows an interactive slider that changes the bulb's brightness as the cursor moves up and down"
                        src={nqwImage3}
                    />
                </div>
            </Link>

            <a
                className="section-wrapper section-button reverse-on-small-screens"
                href="https://bbispaces.com/" 
                target="_blank"
            >
                <div className="left-column">
                    <h2>Black, Brown, &amp; Indigenous Seattle</h2>
                    <p className="light-gray-text">Pro bono project&nbsp;&nbsp;/&nbsp;&nbsp;October 2020 – January 2021</p>
                    <p>A team at the University of Washington community-sourced places in Seattle that create friendly environments for the Black, Brown, and Indigenous people. This interactive map houses the growing list.</p>
                    <a
                        className="read-more-button"
                        href="https://bbispaces.com/" 
                        target="_blank"
                    >
                        See the map
                    </a>
                </div>
                <div className="right-column">
                    <img
                        alt="Map with pins showing minority-friendly places and spaces in the Seattle area"
                        src={nqwImage2}
                    />
                </div>
            </a>

            <a
                className="section-wrapper section-button reverse-on-small-screens"
                href="https://art-game.org/" 
                target="_blank"
            >
                <div className="left-column">
                    <h2>The Art Game</h2>
                    <p className="light-gray-text">Personal project&nbsp;&nbsp;/&nbsp;&nbsp;March 2020 – present</p>
                    <p>The Art Game is an ongoing visual adaptation of Whisper Down the Alley. Each participant receives an inspiration piece and creates a related composition.</p>
                    <a
                        className="read-more-button"
                        href="https://art-game.org/" 
                        target="_blank"
                    >
                        Browse the art
                    </a>
                </div>
                <div className="right-column">
                    <img
                        alt="Image slider showing a drab marsh with an overlaid grid"
                        src={nqwImage1}
                    />
                </div>
            </a>

            <Link
                className="section-wrapper section-button reverse-on-small-screens"
                to='/trailsafe'
            >
                <div className="left-column">
                    <h2>TrailSafe</h2>
                    <p className="light-gray-text">Academic project&nbsp;&nbsp;/&nbsp;&nbsp;October 2020 – December 2020</p>
                    <p>I worked with a team of master's students at the University of Washington to design, prototype, and test a web app that lets hikers access live condition information at trailheads to help them avoid dangerous situations.</p>
                    <Link
                        className="read-more-button"
                        to='/trailsafe'
                    >
                        Read more
                    </Link>
                </div>
                <div className="right-column">
                    <img
                        alt="Phone screen displays information about the hike to Mount Si, including summit weather, a hike overview, and a warning about snow"
                        src={nqwImage4}
                    />
                </div>
            </Link>


            <a
                className="section-wrapper section-button reverse-on-small-screens"
                href="https://www.prnewswire.com/news-releases/lumni-acquires-paytronage-accelerating-isa-growth-in-us-300644741.html"
                target="_blank"
            >
                <div className="left-column">
                    <h2>Paytronage</h2>
                    <p className="light-gray-text">Freelance&nbsp;&nbsp;/&nbsp;&nbsp;June 2017 – February 2018</p>
                    <p>I worked with Paytronage to provide better alternatives for college financing. I led design of an MVP demoed to raise a successful round of seed funding. Paytronage was acquired by Lumni in 2018.</p>
                    <a
                        className="read-more-button"
                        href="https://www.prnewswire.com/news-releases/lumni-acquires-paytronage-accelerating-isa-growth-in-us-300644741.html"
                        target="_blank"
                    >
                        Read the press release
                    </a>
                </div>
                <div className="right-column">
                    <img
                        alt="Mockup of Paytronage's income share agreement tool on a computer monitor"
                        src={sectionCover4}
                    />
                </div>
            </a>

        </Fragment>
    );
}

export default Archive;
