import './App.css';
import { Link } from 'react-router-dom';
import sectionCover1 from './section-cover-1.png';
import sectionCover2 from './section-cover-2.png';
import sectionCover3 from './section-cover-3.png';

function CaseStudyHeader(props) {
    var covers = [
        sectionCover1,
        sectionCover2,
        sectionCover3
    ]
    return (
        <div className="section-wrapper">
            <div className="left-column width-30">
                <h2>Other case studies</h2>
            </div>
            <div className="right-column width-70">
                <div className="flex-wrapper">
                    <Link
                        className="left-column"
                        to={props.otherCaseStudiesSlugs[0]}
                    >
                        <img
                            className="case-study-preview-image"
                            src={covers[props.otherCaseStudiesCovers[0]]}
                        /><br />
                        <Link to={props.otherCaseStudiesSlugs[0]}>
                            {props.otherCaseStudies[0]} →
                        </Link>
                    </Link><br /><br />
                    <Link
                        className="right-column"
                        to={props.otherCaseStudiesSlugs[1]}
                    >
                        <img
                            className="case-study-preview-image"
                            src={covers[props.otherCaseStudiesCovers[1]]}
                        /><br />
                        <Link to={props.otherCaseStudiesSlugs[1]}>
                            {props.otherCaseStudies[1]} →
                        </Link>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CaseStudyHeader;
